import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Button } from '@material-ui/core';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppBar, Toolbar } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { refreshMainData } from '../state';
import TextField from '@material-ui/core/TextField';
import nationality from '../nationality';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MuiPhoneNumber from 'material-ui-phone-number';

const validationSchema1 = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  passwordAgain: yup.string(),
});

const validationSchema2 = yup.object({
  gender: yup
    .string()
    .oneOf(['M', 'F'], 'Select gender')
    .required('Gender is required'),
  cityOfResidence: yup.string().required('City is required'),
  nationality: yup.string().required('Nationality is required'),
  mobilePhone: yup
    .string()
    .required('Mobile phone is required')
    .matches(/[+][0-9]{8,}/, 'Enter a valid phone number'),
  emergencyContactName: yup
    .string()
    .required('Emergency contact name is required'),
  emergencyContactPhone: yup
    .string()
    .required('Emergency contact phone is required')
    .matches(/[+][0-9]{8,}/, 'Enter a valid phone number'),
  dateOfBirth: yup.date().nullable().required('Valid birth date is required'),
});

const validationSchema3 = yup.object({
  jeepModel: yup.string().required('Jeep model is required'),
  jeepYear: yup.string().required('Year is required'),
  jeepersStage: yup
    .string()
    .oneOf(['1', '2', '3'])
    .required('Stage is required'),
  vinNumber: yup.string().required('VIN is required'),
  terms: yup
    .boolean()
    .oneOf([true], 'Please read and accept terms & conditions')
    .required('Please select'),
});

const RegForm = () => {
  let history = useHistory();
  let [step, setStep] = React.useState<1 | 2 | 3>(1);
  let [globalError, setGlobalError] = React.useState<string | null>(null);

  const formik1 = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordAgain: '',
    },
    validationSchema: validationSchema1,
    onSubmit: async (values) => {
      if (values.password !== values.passwordAgain) {
        formik1.setFieldError('passwordAgain', 'Password did not match');
        return;
      }

      try {
        let resp = await axios.post('/api/email/', { email: values['email'] });
        if (resp.data.exists) {
          formik1.setFieldError('email', 'E-mail is already used');
          return;
        }
      } catch (e) {
        setGlobalError('An error happened. Please try again or contact us.');
        return;
      }

      setStep(2);
    },
  });

  const formik2 = useFormik({
    initialValues: {
      cityOfResidence: '',
      gender: '',
      dateOfBirth: null,
      nationality: '',
      mobilePhone: '',
      emergencyContactName: '',
      emergencyContactPhone: '',
    },
    validationSchema: validationSchema2,
    onSubmit: async (values) => {
      setStep(3);
    },
  });

  const formik3 = useFormik({
    initialValues: {
      jeepModel: '',
      jeepYear: '',
      jeepersStage: '',
      vinNumber: '',
      terms: false,
    },
    validationSchema: validationSchema3,
    onSubmit: async (values) => {
      let data = Object.assign(
        {},
        formik1.values,
        formik2.values,
        formik3.values,
        {
          dateOfBirth: format(
            formik2.values['dateOfBirth'] || new Date(),
            'dd/MM/yyyy'
          ),
        }
      );
      try {
        await axios.post('/api/register/', data);
        await refreshMainData();
        history.push('/app/');
      } catch (e) {
        if (e.response && e.response.data) {
          for (const [key, value] of Object.entries(e.response.data)) {
            if (key && Array.isArray(value)) {
              [formik1, formik2, formik3].forEach((f: any) => {
                if (f.values[key] != null) {
                  f.setFieldError(key, value.join(' '));
                }
              });
            }
          }
        }
      }
    },
  });

  if (step === 1) {
    return (
      <>
        <form onSubmit={formik1.handleSubmit}>
          {globalError && <p className="error">{globalError}</p>}

          <TextField
            fullWidth
            id="firstName"
            name="firstName"
            label="First name"
            value={formik1.values.firstName}
            onChange={formik1.handleChange}
            error={
              formik1.touched.firstName && Boolean(formik1.errors.firstName)
            }
            helperText={formik1.touched.firstName && formik1.errors.firstName}
          />

          <TextField
            fullWidth
            id="lastName"
            name="lastName"
            label="Last name"
            value={formik1.values.lastName}
            onChange={formik1.handleChange}
            error={formik1.touched.lastName && Boolean(formik1.errors.lastName)}
            helperText={formik1.touched.lastName && formik1.errors.lastName}
          />

          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik1.values.email}
            onChange={formik1.handleChange}
            error={formik1.touched.email && Boolean(formik1.errors.email)}
            helperText={formik1.touched.email && formik1.errors.email}
          />

          <TextField
            fullWidth
            id="password"
            name="password"
            label="Password"
            type="password"
            value={formik1.values.password}
            onChange={formik1.handleChange}
            error={formik1.touched.password && Boolean(formik1.errors.password)}
            helperText={formik1.touched.password && formik1.errors.password}
          />

          <TextField
            fullWidth
            id="password_again"
            name="passwordAgain"
            label="Password again"
            type="password"
            value={formik1.values.passwordAgain}
            onChange={formik1.handleChange}
            error={
              formik1.touched.passwordAgain &&
              Boolean(formik1.errors.passwordAgain)
            }
            helperText={
              formik1.touched.passwordAgain && formik1.errors.passwordAgain
            }
          />

          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={formik1.isSubmitting}
          >
            {formik1.isSubmitting ? <CircularProgress size={20} /> : 'Next'}
          </Button>
        </form>
        <p style={{ padding: '20px' }}>
          Already have an account yet? <Link to="/app/">Log in here.</Link>
        </p>
      </>
    );
  }

  if (step === 2) {
    return (
      <form onSubmit={formik2.handleSubmit}>
        {globalError && <p className="error">{globalError}</p>}

        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Gender</InputLabel>
          <Select
            native
            value={formik2.values.gender}
            onChange={formik2.handleChange}
            label="Gender"
            error={formik2.touched.gender && Boolean(formik2.errors.gender)}
            inputProps={{
              name: 'gender',
              id: 'outlined-age-native-simple',
            }}
          >
            <option aria-label="None" value="" />
            <option value="M">Male</option>
            <option value="F">Female</option>
          </Select>
        </FormControl>
        {formik2.touched.gender && formik2.errors.gender && (
          <p className="error">{formik2.errors.gender}</p>
        )}

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            id="birth"
            label="Birth date (DD/MM/YYYY)"
            format="dd/MM/yyyy"
            inputVariant="outlined"
            value={formik2.values.dateOfBirth}
            onChange={(value) => {
              if (value && isNaN(value.getTime())) {
                formik2.setFieldValue('dateOfBirth', null);
              } else {
                formik2.setFieldValue('dateOfBirth', value);
              }
            }}
            KeyboardButtonProps={{
              'aria-label': 'Change dateOfBirth date',
            }}
          />
        </MuiPickersUtilsProvider>
        {formik2.touched.dateOfBirth && formik2.errors.dateOfBirth && (
          <p className="error">{formik2.errors.dateOfBirth}</p>
        )}

        {/* <TextField
          fullWidth
          id="city"
          name="cityOfResidence"
          label="City of residence"
          value={formik2.values.cityOfResidence}
          onChange={formik2.handleChange}
          error={
            formik2.touched.cityOfResidence &&
            Boolean(formik2.errors.cityOfResidence)
          }
          helperText={
            formik2.touched.cityOfResidence && formik2.errors.cityOfResidence
          }
        /> */}

        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">
            City of Residence
          </InputLabel>
          <Select
            native
            value={formik2.values.cityOfResidence}
            onChange={formik2.handleChange}
            label="City of Residence"
            error={
              formik2.touched.cityOfResidence &&
              Boolean(formik2.errors.cityOfResidence)
            }
            inputProps={{
              name: 'cityOfResidence',
              id: 'outlined-age-native-simple',
            }}
          >
            <option aria-label="None" value="" />
            <option value="Abu Dhabi">Abu Dhabi</option>
            <option value="Dubai">Dubai</option>
            <option value="Ajman">Ajman</option>
            <option value="Fujairah">Fujairah</option>
            <option value="Ras al Khaimah">Ras al Khaimah</option>
            <option value="Sharjah ">Sharjah </option>
            <option value="Umm al Quwain">Umm al Quwain</option>
            <option value="Other">Other</option>
          </Select>
        </FormControl>
        {formik2.touched.cityOfResidence && formik2.errors.cityOfResidence && (
          <p className="error">{formik2.errors.cityOfResidence}</p>
        )}

        {/*
        <TextField
          fullWidth
          id="nationality"
          name="nationality"
          label="Nationality"
          value={formik2.values.nationality}
          onChange={formik2.handleChange}
          error={
            formik2.touched.nationality && Boolean(formik2.errors.nationality)
          }
          helperText={formik2.touched.nationality && formik2.errors.nationality}
        />
        */}

        <Autocomplete
          id="nationality"
          style={{ width: '100%', marginTop: '20px' }}
          options={nationality}
          defaultValue={formik2.values.nationality}
          inputValue={formik2.values.nationality}
          onChange={(event: any, newValue: string | null) => {
            formik2.setFieldValue('nationality', newValue || '');
          }}
          onInputChange={(event, newInputValue) => {
            formik2.setFieldValue('nationality', newInputValue);
          }}
          autoHighlight
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Nationality"
              variant="outlined"
              name="nationality"
              error={
                formik2.touched.nationality &&
                Boolean(formik2.errors.nationality)
              }
              helperText={
                formik2.touched.nationality && formik2.errors.nationality
              }
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />

        {/*
        <TextField
          fullWidth
          id="mobilePhone"
          name="mobilePhone"
          label="Mobile Phone"
          value={formik2.values.mobilePhone}
          onChange={formik2.handleChange}
          error={
            formik2.touched.mobilePhone && Boolean(formik2.errors.mobilePhone)
          }
          helperText={formik2.touched.mobilePhone && formik2.errors.mobilePhone}
        />
        */}

        <MuiPhoneNumber
          id="mobilePhone"
          preferredCountries={['ae']}
          defaultCountry="ae"
          placeholder="+971 (050) 1234567"
          name="mobilePhone"
          label="Mobile Phone"
          value={formik2.values.mobilePhone}
          onChange={(value) => formik2.setFieldValue('mobilePhone', value)}
        />
        {formik2.touched.mobilePhone && formik2.errors.mobilePhone && (
          <p className="error">{formik2.errors.mobilePhone}</p>
        )}

        <TextField
          fullWidth
          id="emergencyContactName"
          name="emergencyContactName"
          label="Emergency Contact Name"
          value={formik2.values.emergencyContactName}
          onChange={formik2.handleChange}
          error={
            formik2.touched.emergencyContactName &&
            Boolean(formik2.errors.emergencyContactName)
          }
          helperText={
            formik2.touched.emergencyContactName &&
            formik2.errors.emergencyContactName
          }
        />

        {/*
        <TextField
          fullWidth
          id="emergencyContactPhone"
          name="emergencyContactPhone"
          label="Emergency Contact Phone"
          value={formik2.values.emergencyContactPhone}
          onChange={formik2.handleChange}
          error={
            formik2.touched.emergencyContactPhone &&
            Boolean(formik2.errors.emergencyContactPhone)
          }
          helperText={
            formik2.touched.emergencyContactPhone &&
            formik2.errors.emergencyContactPhone
          }
        />
        */}

        <MuiPhoneNumber
          preferredCountries={['ae']}
          defaultCountry="ae"
          placeholder="+971 (050) 1234567"
          id="emergencyContactPhone"
          name="emergencyContactPhone"
          value={formik2.values.emergencyContactPhone}
          label="Emergency Contact Phone"
          onChange={(value) =>
            formik2.setFieldValue('emergencyContactPhone', value)
          }
        />
        {formik2.touched.emergencyContactPhone &&
          formik2.errors.emergencyContactPhone && (
            <p className="error">{formik2.errors.emergencyContactPhone}</p>
          )}

        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => setStep(1)}
        >
          Back
        </Button>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          disabled={formik2.isSubmitting}
        >
          {formik2.isSubmitting ? <CircularProgress size={20} /> : 'Next'}
        </Button>
      </form>
    );
  }

  if (step === 3) {
    return (
      <form onSubmit={formik3.handleSubmit}>
        {globalError && <p className="error">{globalError}</p>}

        {/*
        <TextField
          fullWidth
          id="jeepModel"
          name="jeepModel"
          label="Jeep model"
          value={formik3.values.jeepModel}
          onChange={formik3.handleChange}
          error={formik3.touched.jeepModel && Boolean(formik3.errors.jeepModel)}
          helperText={formik3.touched.jeepModel && formik3.errors.jeepModel}
        />
        */}

        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">
            Jeep Model
          </InputLabel>
          <Select
            native
            value={formik3.values.jeepModel}
            onChange={formik3.handleChange}
            label="Jeep Model"
            error={
              formik3.touched.jeepModel && Boolean(formik3.errors.jeepModel)
            }
            inputProps={{
              name: 'jeepModel',
              id: 'outlined-age-native-simple',
            }}
          >
            <option aria-label="None" value="" />
            <option value="JT">JT Gladiator (2020 to present)</option>
            <option value="JL">JL (2018 to present)</option>
            <option value="JK">JK (2007-2017)</option>
            <option value="TJ">TJ (1997-2006)</option>
            <option value="YJ">YJ (1987-1996)</option>
            <option value="CJ">CJ (1953-1986)</option>
          </Select>
        </FormControl>
        {formik3.touched.jeepModel && formik3.errors.jeepModel && (
          <p className="error">{formik3.errors.jeepModel}</p>
        )}

        <TextField
          fullWidth
          id="jeepYear"
          name="jeepYear"
          label="Jeep Year"
          value={formik3.values.jeepYear}
          onChange={formik3.handleChange}
          error={formik3.touched.jeepYear && Boolean(formik3.errors.jeepYear)}
          helperText={formik3.touched.jeepYear && formik3.errors.jeepYear}
        />

        <TextField
          fullWidth
          id="vin"
          name="vinNumber"
          label="VIN"
          value={formik3.values.vinNumber}
          onChange={formik3.handleChange}
          error={formik3.touched.vinNumber && Boolean(formik3.errors.vinNumber)}
          helperText={formik3.touched.vinNumber && formik3.errors.vinNumber}
        />

        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">
            Jeepers Stage
          </InputLabel>
          <Select
            native
            value={formik3.values.jeepersStage}
            onChange={formik3.handleChange}
            label="Jeepers Stage"
            error={
              formik3.touched.jeepersStage &&
              Boolean(formik3.errors.jeepersStage)
            }
            inputProps={{
              name: 'jeepersStage',
              id: 'outlined-age-native-simple',
            }}
          >
            <option aria-label="None" value="" />
            <option value="1">Stage 1</option>
            <option value="2">Stage 2</option>
            <option value="3">Stage 3</option>
          </Select>
        </FormControl>
        {formik3.touched.jeepersStage && formik3.errors.jeepersStage && (
          <p className="error">{formik3.errors.jeepersStage}</p>
        )}

        <div className="terms">
          <p>
            I have read and understood{' '}
            <a href="/terms/" target="_blank">
              terms and conditions
            </a>{' '}
            and accept the contents in their entirety.
          </p>
          <FormControlLabel
            control={
              <Checkbox
                checked={formik3.values.terms}
                onChange={formik3.handleChange}
                name="terms"
              />
            }
            label="Accept the Jeepers Terms & Conditions"
          />
          {formik3.touched.terms && formik3.errors.terms && (
            <p className="error">{formik3.errors.terms}</p>
          )}
        </div>

        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => setStep(2)}
        >
          Back
        </Button>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          disabled={formik3.isSubmitting}
        >
          {formik3.isSubmitting ? <CircularProgress size={20} /> : 'Submit'}
        </Button>
      </form>
    );
  }

  return null;
};

export default function Register() {
  return (
    <Root>
      <AppBar position="static" style={{ background: '#E87722' }}>
        <Toolbar>
          <h1>Register</h1>
        </Toolbar>
      </AppBar>
      <RegForm />
    </Root>
  );
}

let Root = styled.div`
  .terms {
    color: #444;
  }

  form {
    padding: 20px 20px;
  }

  form > * {
    margin: 8px 0;
    width: 100%;
  }

  h1 {
    flex: 1;
    font-size: 1.2em;
    text-align: center;
    text-transform: uppercase;
  }

  .error {
    color: #f44336;
    font-size: 0.75rem;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }
`;
