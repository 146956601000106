import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Button, AppBar, Toolbar, FormHelperText } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextField from '@material-ui/core/TextField';
import { refreshMainData } from '../state';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
});

export default function Login() {
  let [error, setError] = React.useState<string | null>(null);

  let formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setError(null);
      try {
        await axios.post('/api/login/', values);
        await refreshMainData();
      } catch (e) {
        if (e.response && e.response.status === 403) {
          setError('E-mail or password incorrect.');
        } else {
          setError(
            'Failed to check login data. Please try again or contact us.'
          );
        }
      }
    },
  });
  return (
    <Wrap>
      <AppBar position="static" style={{ background: '#E87722' }}>
        <Toolbar>
          <h1>Log in</h1>
        </Toolbar>
      </AppBar>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          fullWidth
          id="password"
          name="password"
          label="Password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <Button color="primary" variant="contained" fullWidth type="submit">
          Log in
        </Button>
        {error && <FormHelperText error>{error}</FormHelperText>}
      </form>

      <p>
        Don't have an account yet?{' '}
        <Link to="/app/register">Register&nbsp;here.</Link>
        <br />
        If you have forgotten your password, you can{' '}
        <Link to="/app/reset_password">reset it here.</Link>
      </p>
    </Wrap>
  );
}

const Wrap = styled.div`
  & > p {
    padding: 20px;
    color: #444;
  }

  & > p > a {
    color: #444;
  }

  form {
    padding: 20px;
  }

  form > * {
    margin: 10px 0;
  }

  h1 {
    flex: 1;
    font-size: 1.2em;
    text-align: center;
    text-transform: uppercase;
  }
`;
