import React from 'react';
import styled from 'styled-components';
import RichText from '../components/RichText';
import { AppStore, useTitle } from '../state';
import defaultBgImage from '../imgs/about-header.jpg';

export default function About() {
  let about = AppStore.useState((s) => s.main?.site?.about) || '';
  let aboutImage = AppStore.useState((s) => s.main?.site?.aboutImage) || '';
  let aboutUrl = AppStore.useState((s) => s.main?.site?.aboutUrl) || '';
  useTitle('About');

  let bgStyle = {
    background: `url('${aboutImage || defaultBgImage}') 50% 50%`,
    backgroundSize: 'cover',
    cursor: aboutUrl ? 'pointer' : undefined,
  };

  return (
    <Wrap>
      <div
        className="background-container"
        style={bgStyle}
        onClick={() => {
          if (aboutUrl) {
            let win = window.open(aboutUrl, '_blank');
            if (win) win.focus();
          }
        }}
      >
        {/*
        <img
          src={require('../imgs/club-logo200.png').default}
          alt="Jeepers logo"
        />
        */}
      </div>
      <div className="about">
        <RichText html={about} />
      </div>
    </Wrap>
  );
}

const Wrap = styled.div`
  .about {
    padding: 0 20px 40px 20px;
    line-height: 1.4;
    font-size: 0.9em;

    p {
      margin: 10px 0;
    }
  }

  .background-container {
    width: 100%;
    padding-top: 50%; /* trick to create a desired aspect-ratio */
    background: url('${defaultBgImage}') 50% 50%;
    background-size: cover;
    position: relative;
    margin-bottom: 90px;
    margin-bottom: 40px;
  }

  .background-container > img {
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 100px;
    width: 100px;
    border: 6px solid #444;
    border-radius: 50%;
    object-fit: contain;
    background: white;
    transform: translate(-50%, 50%);
  }
`;
