import React from 'react';
import styled from 'styled-components';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { format, formatDuration } from 'date-fns';
import { Event, useTitle, isEligible } from '../state';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import NextIcon from '@material-ui/icons/NavigateNext';
import PrevIcon from '@material-ui/icons/NavigateBefore';
import { AppStore, fetchEvents } from '../state';

interface PathParams {
  year: string;
  month: string;
  type: string;
}

export default function EventList(props: RouteComponentProps<PathParams>) {
  let params = props.match.params;
  let year = parseInt(params.year, 10);
  let month = parseInt(params.month, 10);
  // eslint-disable-next-line
  let [grade, level] = AppStore.useState((s) => [
    s.main?.me.grade || 5,
    s.main?.me.level || 3,
  ]);

  let events: Event[] | undefined = AppStore.useState(
    (s) => s.eventsList[`${year}/${month}`],
    [year, month]
  );
  let finished = events != null;

  React.useEffect(() => {
    fetchEvents(year, month);
  }, [year, month]);

  let [prev, next] = getPeriods(year, month);
  events = (events || []).filter((e) =>
    params.type === 'drive' ? e.isDrive : !e.isDrive
  );
  let isEmpty = finished && events.length === 0;

  useTitle(params.type === 'drive' ? 'Drives' : 'Events');

  return (
    <Wrap>
      <PullToRefresh
        onRefresh={() => fetchEvents(year, month)}
        pullingContent=""
      >
        <>
          <div className="header">
            <Link to={`/app/book/${params.type}/${prev.year}/${prev.month}`}>
              <IconButton aria-label="Previous month">
                <PrevIcon />
              </IconButton>
            </Link>

            <span>{format(new Date(year, month - 1, 15), 'LLLL yyyy')}</span>

            <Link to={`/app/book/${params.type}/${next.year}/${next.month}`}>
              <IconButton aria-label="Next month">
                <NextIcon />
              </IconButton>
            </Link>
          </div>

          <div className="body">
            {!finished && <p className="loading">Loading</p>}
            {isEmpty && (
              <p className="empty">
                No {params.type === 'event' ? 'events' : 'drives'}
              </p>
            )}
            {events.map((e) => (
              <EventComponent
                key={e.eventId}
                event={e}
                grade={grade}
                level={level}
              />
            ))}
          </div>
        </>
      </PullToRefresh>
    </Wrap>
  );
}

let Wrap = styled.div`
  & > div {
    padding-bottom: 50px;
  }

  .header {
    display: flex;
    align-items: center;
    padding: 5px 20px;
  }

  .header > span {
    display: block;
    flex: 1;
    text-align: center;
  }

  .loading {
    display: flex;
    min-height: 250px;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
  }

  .empty {
    display: flex;
    min-height: 250px;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
  }
`;

interface YearMonth {
  year: number;
  month: number;
}

function getPeriods(year: number, month: number): [YearMonth, YearMonth] {
  return [
    {
      year: month === 1 ? year - 1 : year,
      month: month === 1 ? 12 : month - 1,
    },
    {
      year: month === 12 ? year + 1 : year,
      month: month === 12 ? 1 : month + 1,
    },
  ];
}

interface EventComponentProps {
  event: Event;
  grade: number;
  level: number;
}

function EventComponent({ event, grade, level }: EventComponentProps) {
  let history = useHistory();
  let cls = '';
  if (!isEligible(grade, level, event)) {
    cls = 'requirements_not_met';
  } else if (!event.available || (event.capacity != null && event.left === 0)) {
    cls = 'unavailable';
  }
  return (
    <EventWrap
      className={cls}
      onClick={() => history.push(`/app/event/${event.eventId}`)}
    >
      <div className="event-header">
        {format(event.startsAt, 'EEEE, do LLLL yyyy')}
      </div>

      <div className="event-body">
        <div className="event-time">{format(event.startsAt, 'HH:mm')}</div>
        <div className="event-main">
          <p>
            <strong>{event.name}</strong>
          </p>
          <p>{event.locationName}</p>
          <p>
            Capacity:{' '}
            {event.capacity == null
              ? 'Unlimited'
              : `${event.capacity} (${event.left} left)`}
          </p>
          <p>
            Duration:{' '}
            {event.durationMinutes == null
              ? 'All day'
              : formatDuration({
                  hours: Math.floor(event.durationMinutes / 60),
                  minutes: event.durationMinutes % 60,
                })}
          </p>
        </div>
      </div>
    </EventWrap>
  );
}

let EventWrap = styled.div`
  cursor: pointer;

  &:hover {
    background: #f0f0f0;
  }

  &.requirements_not_met .event-body {
    background: repeating-linear-gradient(
      45deg,
      #fcd8d8,
      #fcd2d2 10px,
      #ffffff 10px,
      #ffffff 20px
    );
  }

  &.requirements_not_met .event-body > * {
    opacity: 0.7;
  }

  &.unavailable {
    background: #f0f0f0;
  }

  &.unavailable > * {
    opacity: 0.6;
  }

  .event-header {
    padding: 6px 10px;
    background: #ddd;
  }

  .event-body {
    padding: 10px 0;
    display: flex;
  }

  .event-time {
    display: flex;
    width: 80px;
    font-size: 1.2em;
    align-items: center;
    justify-content: center;
  }

  .event-main p:not(:first-child) {
    font-size: 0.9em;
    line-height: 1.3;
  }
`;
