import React from 'react';
import { Store } from 'pullstate';
import axios from 'axios';
import { createAsyncAction, errorResult, successResult } from 'pullstate';
import * as storage from './storage';

declare global {
  interface Window {
    APP_VERSION: string;
  }
}
let VERSION = window.APP_VERSION;
if (window.console) console.log('App version', VERSION);

let mainData = storage.get<MainData>('main_data');
if (mainData) prepareMainData(mainData);

export const AppStore = new Store<AppState>({
  appVersion: VERSION,
  serviceWorkerVersion: null,
  loading: mainData == null,
  auth: mainData != null,
  title: '',
  back: null,
  main: mainData,
  eventsList: {},
  events: {},
});

interface AppState {
  appVersion: string;
  serviceWorkerVersion: string | null;
  loading: boolean;
  auth: boolean;
  title: string;
  back: string | null;
  install?: any;
  promptRefresh?: any;
  main: MainData | null;
  eventsList: { [key: string]: Event[] };
  events: { [key: number]: EventDetails };
}

export function useTitle(title: string, back: null | string = null) {
  React.useEffect(() => {
    AppStore.update((s) => {
      s.title = title;
      s.back = back;
    });
    return () => {
      AppStore.update((s) => {
        s.title = '';
        s.back = null;
      });
    };
  }, [title, back]);
}

interface User {
  userId: number;
}

export interface Event {
  eventId: number;
  isDrive: boolean;
  name: string;
  locationName: string;
  locationUrl: string;
  startsAt: Date;
  capacity: number | null;
  left: number;
  available: boolean;
  durationMinutes: number | null;
  eventInfo: string;
  jeepRequirements: string;
  level: number;
  grade: number;
  meetingPoint: string;
  meetingPointUrl: string;
}

export interface EventDetails {
  eventId: number;
  isDrive: boolean;
  name: string;
  locationName: string;
  locationUrl: string;
  startsAt: Date;
  available: boolean;
  capacity: number | null;
  left: number;
  durationMinutes: number | null;
  eventInfo: string;
  headerImage?: string;
  headerUrl: string;
  grade: number;
  level: number;
  jeepRequirements: string;
  meetingPoint: string;
  meetingPointUrl: string;
  marshals: Array<{
    userId: number;
    firstName: string;
    image?: string;
  }>;
  jeepers: Array<{
    userId: number;
    firstName: string;
    image?: string;
  }>;
  bookingStatus: null | 0 | 1 | 2;
}

export function isEligible(
  grade: number,
  level: number,
  event: { isDrive: boolean; grade: number; level: number }
): boolean {
  if (!event.isDrive) return true;
  return grade > event.grade || (grade === event.grade && level >= event.level);
}

interface MainData {
  users: User[];
  me: {
    userId: number;
    firstName: string;
    lastName: string;
    image?: string;
    isStaff: boolean;
    isVerified: boolean;
    isMarshal: boolean;
    level: number;
    grade: number;
  };
  profile: Profile;
  site: {
    about: string;
    aboutUrl: string;
    homepageImage: string;
    aboutImage: string;
  };
  marshals: Marshal[];
  nextEvents: Event[];
  vapidKey: string;
}

export interface Profile {
  cityOfResidence: string;
  gender: string;
  dateOfBirth?: Date;
  nationality: string;
  mobilePhone: string;
  emergencyContactName: string;
  emergencyContactPhone: string;
  jeepModel: string;
  jeepYear: string;
  jeepersStage: string;
  vinNumber: string;
}

export interface Marshal {
  userId: number;
  firstName: string;
  lastName: string;
  searchTerm: string;
  biography: string;
  memberSince: number;
  vehicleDetails: string;
  mobilePhone: string;
  level: number;
  grade: number;
  image?: string;
  headerImage?: string;
}

function prepareMainData(data: any) {
  if (data.nextEvents) {
    data.nextEvents.map((e: Event) => (e.startsAt = new Date(e.startsAt)));
  }

  if (data.profile && data.profile.dateOfBirth) {
    data.profile.dateOfBirth = new Date(data.profile.dateOfBirth);
  }

  data.marshals.forEach((m: Marshal) => {
    m.searchTerm =
      m.firstName.replace(/\W/gi, '').toLowerCase() +
      m.lastName.replace(/\W/gi, '').toLowerCase();
  });
}

export async function refreshMainData() {
  try {
    let resp = await axios.get('/api/main/');
    storage.set('main_data', resp.data);

    AppStore.update((s) => {
      prepareMainData(resp.data);
      s.main = resp.data;
      s.auth = true;
      s.loading = false;
    });
  } catch (e) {
    if (e.response) {
      if (e.response.status === 403) {
        AppStore.update((s) => {
          s.auth = false;
          s.loading = false;
        });
      }
    } else {
      let data = storage.get('main_data') as any;
      if (data) {
        AppStore.update((s) => {
          prepareMainData(data);
          s.main = data;
          s.auth = true;
          s.loading = false;
        });
      }
    }
  }
}

export async function logout() {
  await axios.delete('/api/login/');
  AppStore.update((s) => {
    s.auth = false;
  });
}

refreshMainData();

export async function fetchEvents(year: number, month: number) {
  try {
    const resp = await axios.get('/api/events/', { params: { year, month } });
    resp.data.forEach((d: Event) => {
      d.startsAt = new Date(d.startsAt);
    });
    AppStore.update((s) => {
      s.eventsList[`${year}/${month}`] = resp.data;
    });
    return true;
  } catch (e) {
    return false;
  }
}

export async function fetchEvent(id: number) {
  try {
    const resp = await axios.get(`/api/event/${id}/`, {});
    resp.data.startsAt = new Date(resp.data.startsAt);
    AppStore.update((s) => {
      s.events[id] = resp.data;
    });
    return true;
  } catch (e) {
    return false;
  }
}
