import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Button, Avatar } from '@material-ui/core';
import { AppStore, useTitle, refreshMainData } from '../state';
import ProfileForm from './ProfileForm';
import PasswordForm from './PasswordForm';

export default function Settings() {
  let me = AppStore.useState((s) => s.main?.me);
  useTitle('Settings');

  const [file, setFile] = React.useState(null);

  function handleUpload(event: any) {
    setFile(event.target.files[0]);
  }

  async function submit(e: any) {
    if (file == null) return;
    let data = new FormData();
    data.append('file', file as any);
    try {
      await axios.post('/api/me/photo/', data, {
        headers: {
          accept: 'application/json',
          'content-type': `multipart/form-data`,
        },
      });
      await refreshMainData();
    } catch (e) {
      console.log(e);
      // pass
    }
  }

  if (me == null) return null;

  return (
    <Wrap>
      <h2>Change Photo</h2>
      <form className="image-form">
        <Avatar alt="My image" src={me.image} />
        <div>
          <input type="file" onChange={handleUpload} />
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={submit}
          >
            Update
          </Button>
        </div>
      </form>

      <h2>Change Password</h2>
      <PasswordForm />

      <h2>Profile</h2>
      <ProfileForm />
    </Wrap>
  );
}

const Wrap = styled.div`
  padding: 20px;

  .MuiAvatar-root {
    width: 80px;
    height: 80px;
    margin-right: 20px;
  }

  .image-form {
    display: flex;
    align-items: center;

    & > div:last-child {
      flex: 1;
      box-sizing: border-box;
      max-width: 240px;
      overflow-x: hidden;
      padding: 10px 10px 10px 0;
    }

    .MuiButtonBase-root {
      margin-top: 10px;
      width: 100px;
    }
  }

  h2 {
    font-size: 18px;
    color: #444;
    margin-bottom: 10px;
  }

  h2:not(:first-child) {
    margin-top: 30px;
  }
`;
