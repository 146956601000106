import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { AppStore, logout } from '../state';
import gamifierLogo from '../imgs/gamifier.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  backButton: {
    marginRight: '0px',
  },
  title: {
    flexGrow: 1,
  },
}));

export default function JeepersAppBar() {
  let [title, back, isStaff, install] = AppStore.useState((s) => [
    s.title,
    s.back,
    s.main?.me.isStaff || false,
    s.install,
  ]);
  let history = useHistory();
  let [appVersion, serviceWorkerVersion] = AppStore.useState((s) => [
    s.appVersion,
    s.serviceWorkerVersion,
  ]);
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const loc = useLocation();
  React.useEffect(() => setMenuOpen(false), [loc]);

  let now = new Date();
  let year = now.getFullYear();
  let month = now.getMonth() + 1;

  return (
    <>
      <AppBar position="static" style={{ background: '#E87722' }}>
        <Toolbar>
          {back && (
            <IconButton
              edge="start"
              className={classes.backButton}
              color="inherit"
              aria-label="back"
              onClick={() => history.push(back)}
            >
              <ArrowBackIosIcon />
            </IconButton>
          )}
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => setMenuOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={menuOpen} onClose={() => setMenuOpen(false)}>
        <Nav>
          <img
            id="sidenav-close"
            onClick={() => setMenuOpen(false)}
            src={require('../imgs/close.svg').default}
            width="30"
            height="30"
            alt="Close"
          />

          <Link to="/app/">Home</Link>
          <Link to={`/app/book/event/${year}/${month}`}>Book Event</Link>
          <Link to={`/app/book/drive/${year}/${month}`}>Book Drive</Link>
          <Link to="/app/marshals">Marshalls</Link>
          <Link to="/app/settings">Settings</Link>
          {isStaff && (
            <a href="/admin" target="_blank">
              Admin
            </a>
          )}
          <Link to="/app/about">About</Link>
          {install && (
            <div
              className="installApp"
              onClick={() => install.prompt && install.prompt()}
            >
              Install app
            </div>
          )}
          <div className="logout" onClick={logout}>
            Log out
          </div>
          <span id="version">
            <a
              href="https://www.gamifier.co.uk/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={gamifierLogo} alt="Gamifier Group" />
            </a>
            v{appVersion}
            {serviceWorkerVersion ? ` (${serviceWorkerVersion})` : ''}
          </span>
        </Nav>
      </Drawer>
    </>
  );
}

const Nav = styled.nav`
  height: 100%;
  width: 250px;
  background-color: #e87722;
  padding-top: 70px;
  position: relative;
  display: flex;
  flex-direction: column;

  & > a,
  & > div {
    padding: 6px 8px 6px 32px;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    font-size: 25px;
    color: white;
    display: block;
  }

  .logout {
    margin-top: auto;
    margin-bottom: 40px;
    font-size: 20px;
    cursor: pointer;
  }

  .installApp {
    background: white;
    border-radius: 5px;
    margin: 10px 20px;
    color: #e87722;
    height: 45px;
    line-height: 45px;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    padding: 0;
    cursor: pointer;
  }

  #sidenav-close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  #version {
    display: flex;
    align-items: center;
    color: white;
    position: absolute;
    left: 32px;
    bottom: 10px;
    font-size: 10px;
    opacity: 0.5;
  }

  #version img {
    display: inline;
    width: 16px;
    margin-right: 5px;
  }
`;
