import React from 'react';
import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import { Avatar } from '@material-ui/core';
import { AppStore, useTitle, Marshal, refreshMainData } from '../state';

export default function MarshalList() {
  useTitle('Marshalls');
  let [search, setSearch] = React.useState<string>('');
  let marshals = AppStore.useState((s) => s.main?.marshals || []);
  let regex = new RegExp(
    search
      .toLowerCase()
      .split(/\W+/)
      .map((m) => m.replace(/[^\w]/g, ''))
      .join('|')
  );

  return (
    <Wrap>
      <Search value={search} onChange={setSearch} />
      <ul>
        {marshals
          .filter((m) => m.searchTerm.match(regex) != null)
          .map((m) => (
            <MarshalItem key={m.userId} {...m} />
          ))}
      </ul>
    </Wrap>
  );
}

const Wrap = styled.div`
  ul {
    padding: 0;
    margin: 0;
  }
`;

interface SearchProps {
  value: string;
  onChange: (val: string) => any;
}

function Search(props: SearchProps) {
  let [focus, setFocus] = React.useState<boolean>(false);

  return (
    <SearchWrap>
      <div className={focus ? 'focused' : undefined}>
        <SearchIcon />
        <input
          value={props.value}
          placeholder="Search"
          onChange={(e) => props.onChange(e.target.value)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </div>
    </SearchWrap>
  );
}

const SearchWrap = styled.div`
  background: #e87722;
  padding: 5px 10px 10px 10px;
  color: #aaa;

  input {
    border: none;
    background: none;
    height: 40px;
    color: #333;
    outline: none;
  }

  & > div {
    background: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    transition: background 0.5ms ease-in-out;
  }

  & > div.focused {
    background: #eee;
  }
`;

function MarshalItem(marshal: Marshal) {
  return (
    <MarshalLi>
      <Link to={`/app/marshal/${marshal.userId}`}>
        <Avatar alt={`${marshal.firstName}'s image`} src={marshal.image} />
        {marshal.firstName} {marshal.lastName}
      </Link>
    </MarshalLi>
  );
}

let MarshalLi = styled.li`
  a {
    list-style: none;
    padding: 10px 10px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
  }

  .MuiAvatar-root {
    margin-right: 10px;
  }
`;
