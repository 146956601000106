import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { refreshMainData, AppStore } from '../state';
import TextField from '@material-ui/core/TextField';
import nationality from '../nationality';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MuiPhoneNumber from 'material-ui-phone-number';

const validationSchema1 = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  gender: yup
    .string()
    .oneOf(['M', 'F'], 'Select gender')
    .required('Gender is required'),
  cityOfResidence: yup.string().required('City is required'),
  nationality: yup.string().required('Nationality is required'),
  mobilePhone: yup
    .string()
    .required('Mobile phone is required')
    .matches(/[+][0-9]{8,}/, 'Enter a valid phone number'),
  emergencyContactName: yup
    .string()
    .required('Emergency contact name is required'),
  emergencyContactPhone: yup
    .string()
    .required('Emergency contact phone is required')
    .matches(/[+][0-9]{8,}/, 'Enter a valid phone number'),
  dateOfBirth: yup.date().nullable().required('Valid birth date is required'),
  jeepModel: yup.string().required('Jeep model is required'),
  jeepYear: yup.string().required('Year is required'),
  jeepersStage: yup
    .string()
    .oneOf(['1', '2', '3'])
    .required('Stage is required'),
  vinNumber: yup.string().required('VIN is required'),
});

const RegForm = () => {
  let [globalError, setGlobalError] = React.useState<string | null>(null);
  let profile = AppStore.useState((s) =>
    Object.assign(
      {
        firstName: s.main?.me.firstName || '',
        lastName: s.main?.me.lastName || '',
      },
      s.main?.profile || {
        cityOfResidence: '',
        gender: '',
        dateOfBirth: null,
        nationality: '',
        mobilePhone: '',
        emergencyContactName: '',
        emergencyContactPhone: '',
        jeepModel: '',
        jeepYear: '',
        jeepersStage: '',
        vinNumber: '',
      }
    )
  );

  const formik1 = useFormik({
    initialValues: profile,
    validationSchema: validationSchema1,
    onSubmit: async (data) => {
      try {
        // TODO date format! timezone!
        await axios.post('/api/update_profile/', {
          ...data,
          dateOfBirth: data.dateOfBirth?.toISOString()?.split('T')[0],
        });
        await refreshMainData();
      } catch (e) {
        if (e.response && e.response.data) {
          for (const [key, value] of Object.entries(e.response.data)) {
            if (key && Array.isArray(value)) {
              [formik1].forEach((f: any) => {
                if (f.values[key] != null) {
                  f.setFieldError(key, value.join(' '));
                }
              });
            }
          }
        } else {
          setGlobalError('An error happened. Please try again or contact us.');
        }
      }
    },
  });

  return (
    <>
      <form onSubmit={formik1.handleSubmit}>
        {globalError && <p className="error">{globalError}</p>}

        <TextField
          fullWidth
          id="firstName"
          name="firstName"
          label="First name"
          value={formik1.values.firstName}
          onChange={formik1.handleChange}
          error={formik1.touched.firstName && Boolean(formik1.errors.firstName)}
          helperText={formik1.touched.firstName && formik1.errors.firstName}
        />

        <TextField
          fullWidth
          id="lastName"
          name="lastName"
          label="Last name"
          value={formik1.values.lastName}
          onChange={formik1.handleChange}
          error={formik1.touched.lastName && Boolean(formik1.errors.lastName)}
          helperText={formik1.touched.lastName && formik1.errors.lastName}
        />

        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Gender</InputLabel>
          <Select
            native
            value={formik1.values.gender}
            onChange={formik1.handleChange}
            label="Gender"
            error={formik1.touched.gender && Boolean(formik1.errors.gender)}
            inputProps={{
              name: 'gender',
              id: 'outlined-age-native-simple',
            }}
          >
            <option aria-label="None" value="" />
            <option value="M">Male</option>
            <option value="F">Female</option>
          </Select>
        </FormControl>
        {formik1.touched.gender && formik1.errors.gender && (
          <p className="error">{formik1.errors.gender}</p>
        )}

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            id="birth"
            label="Birth date (DD/MM/YYYY)"
            format="dd/MM/yyyy"
            inputVariant="outlined"
            value={formik1.values.dateOfBirth}
            onChange={(value) => {
              if (value && isNaN(value.getTime())) {
                formik1.setFieldValue('dateOfBirth', null);
              } else {
                formik1.setFieldValue('dateOfBirth', value);
              }
            }}
            KeyboardButtonProps={{
              'aria-label': 'Change dateOfBirth date',
            }}
          />
        </MuiPickersUtilsProvider>
        {formik1.touched.dateOfBirth && formik1.errors.dateOfBirth && (
          <p className="error">{formik1.errors.dateOfBirth}</p>
        )}

        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">
            City of Residence
          </InputLabel>
          <Select
            native
            value={formik1.values.cityOfResidence}
            onChange={formik1.handleChange}
            label="City of Residence"
            error={
              formik1.touched.cityOfResidence &&
              Boolean(formik1.errors.cityOfResidence)
            }
            inputProps={{
              name: 'cityOfResidence',
              id: 'outlined-age-native-simple',
            }}
          >
            <option aria-label="None" value="" />
            <option value="Abu Dhabi">Abu Dhabi</option>
            <option value="Dubai">Dubai</option>
            <option value="Ajman">Ajman</option>
            <option value="Fujairah">Fujairah</option>
            <option value="Ras al Khaimah">Ras al Khaimah</option>
            <option value="Sharjah ">Sharjah </option>
            <option value="Umm al Quwain">Umm al Quwain</option>
            <option value="Other">Other</option>
          </Select>
        </FormControl>
        {formik1.touched.cityOfResidence && formik1.errors.cityOfResidence && (
          <p className="error">{formik1.errors.cityOfResidence}</p>
        )}

        <Autocomplete
          id="nationality"
          style={{ width: '100%', marginTop: '20px' }}
          options={nationality}
          defaultValue={formik1.values.nationality}
          inputValue={formik1.values.nationality}
          onChange={(event: any, newValue: string | null) => {
            formik1.setFieldValue('nationality', newValue || '');
          }}
          onInputChange={(event, newInputValue) => {
            formik1.setFieldValue('nationality', newInputValue);
          }}
          autoHighlight
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Nationality"
              variant="outlined"
              name="nationality"
              error={
                formik1.touched.nationality &&
                Boolean(formik1.errors.nationality)
              }
              helperText={
                formik1.touched.nationality && formik1.errors.nationality
              }
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />

        <MuiPhoneNumber
          preferredCountries={['ae']}
          defaultCountry="ae"
          placeholder="+971 (050) 1234567"
          id="mobilePhone"
          name="mobilePhone"
          label="Mobile Phone"
          value={formik1.values.mobilePhone}
          onChange={(value) => formik1.setFieldValue('mobilePhone', value)}
        />
        {formik1.touched.mobilePhone && formik1.errors.mobilePhone && (
          <p className="error">{formik1.errors.mobilePhone}</p>
        )}

        <TextField
          fullWidth
          id="emergencyContactName"
          name="emergencyContactName"
          label="Emergency Contact Name"
          value={formik1.values.emergencyContactName}
          onChange={formik1.handleChange}
          error={
            formik1.touched.emergencyContactName &&
            Boolean(formik1.errors.emergencyContactName)
          }
          helperText={
            formik1.touched.emergencyContactName &&
            formik1.errors.emergencyContactName
          }
        />

        <MuiPhoneNumber
          preferredCountries={['ae']}
          defaultCountry="ae"
          placeholder="+971 (050) 1234567"
          id="emergencyContactPhone"
          name="emergencyContactPhone"
          value={formik1.values.emergencyContactPhone}
          label="Emergency Contact Phone"
          onChange={(value) =>
            formik1.setFieldValue('emergencyContactPhone', value)
          }
        />
        {formik1.touched.emergencyContactPhone &&
          formik1.errors.emergencyContactPhone && (
            <p className="error">{formik1.errors.emergencyContactPhone}</p>
          )}

        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">
            Jeep Model
          </InputLabel>
          <Select
            native
            value={formik1.values.jeepModel}
            onChange={formik1.handleChange}
            label="Jeep Model"
            error={
              formik1.touched.jeepModel && Boolean(formik1.errors.jeepModel)
            }
            inputProps={{
              name: 'jeepModel',
              id: 'outlined-age-native-simple',
            }}
          >
            <option aria-label="None" value="" />
            <option value="JT">JT Gladiator (2020 to present)</option>
            <option value="JL">JL (2018 to present)</option>
            <option value="JK">JK (2007-2017)</option>
            <option value="TJ">TJ (1997-2006)</option>
            <option value="YJ">YJ (1987-1996)</option>
            <option value="CJ">CJ (1953-1986)</option>
          </Select>
        </FormControl>
        {formik1.touched.jeepModel && formik1.errors.jeepModel && (
          <p className="error">{formik1.errors.jeepModel}</p>
        )}

        <TextField
          fullWidth
          id="jeepYear"
          name="jeepYear"
          label="Jeep Year"
          value={formik1.values.jeepYear}
          onChange={formik1.handleChange}
          error={formik1.touched.jeepYear && Boolean(formik1.errors.jeepYear)}
          helperText={formik1.touched.jeepYear && formik1.errors.jeepYear}
        />

        <TextField
          fullWidth
          id="vin"
          name="vinNumber"
          label="VIN"
          value={formik1.values.vinNumber}
          onChange={formik1.handleChange}
          error={formik1.touched.vinNumber && Boolean(formik1.errors.vinNumber)}
          helperText={formik1.touched.vinNumber && formik1.errors.vinNumber}
        />

        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">
            Jeepers Stage
          </InputLabel>
          <Select
            native
            value={formik1.values.jeepersStage}
            onChange={formik1.handleChange}
            label="Jeepers Stage"
            error={
              formik1.touched.jeepersStage &&
              Boolean(formik1.errors.jeepersStage)
            }
            inputProps={{
              name: 'jeepersStage',
              id: 'outlined-age-native-simple',
            }}
          >
            <option aria-label="None" value="" />
            <option value="1">Stage 1</option>
            <option value="2">Stage 2</option>
            <option value="3">Stage 3</option>
          </Select>
        </FormControl>
        {formik1.touched.jeepersStage && formik1.errors.jeepersStage && (
          <p className="error">{formik1.errors.jeepersStage}</p>
        )}

        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          disabled={formik1.isSubmitting}
        >
          {formik1.isSubmitting ? <CircularProgress size={20} /> : 'Save'}
        </Button>
      </form>
    </>
  );
};

export default function Register() {
  return (
    <Root>
      <RegForm />
    </Root>
  );
}

let Root = styled.div`
  form {
    padding: 0;
  }

  form > * {
    margin: 8px 0;
    width: 100%;
  }

  h1 {
    flex: 1;
    font-size: 1.2em;
    text-align: center;
    text-transform: uppercase;
  }

  .error {
    color: #f44336;
    font-size: 0.75rem;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }
`;
